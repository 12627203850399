<template>
  <div style="padding-bottom: 250px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />
    <template v-else>
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1>{{ !existingMilestone ? "Add" : "Edit" }} Milestone</h1>
        </div>
        <div class="flex pb-3 justify-content-end align-items-center">
          <i
            v-if="existingMilestone"
            class="las la-trash text-20 cursor-pointer"
            @click="deleteMilestone"
          />
        </div>
      </div>
      <Divider />
      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Label</label>
            <div class="p-inputgroup">
              <InputText
                v-model="label"
                mode="decimal"
                placeHolder=""
              />
            </div>
          </div>

          <div class="field col-12 sm:col-6">
            <label class="label">Date</label>
            <Calendar
              v-model="date"
              ref="cal"
              placeHolder=""
              :showIcon="true"
              :minDate="jobStartDate"
              :initialDate="new Date()"
              :maxDate="jobEndDate"
              dateFormat="yy-mm-dd"
            />
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            :label="(existingMilestone ? 'Update' : 'Add') + ' Milestone'"
            :input-class="['is-primary']"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { fetchJob, state } from "../../../services/data_service";
export default {
  name: "MilestoneSlider",
  components: {},
  data() {
    return {
      label: "", // Added label data property for form input binding
      date: "", // Added date data property for form input binding
      submitted: false,
      isLoading: false,
      initialDate: new Date(),
      items: [],
    };
  },
  validations: {
    label: {
      required,
    },
    date: {
      required,
    },
  },
  computed: {
    existingMilestone() {
      return this.$store.getters.selectedObject;
    },
    job() {
      return state.job;
    },
    jobStartDate() {
      return this.job?.date_start?.date
        ? new Date(this.job.date_start.date)
        : null;
    },
    jobEndDate() {
      return this.job?.date_due?.date ? new Date(this.job.date_due.date) : null;
    },
  },
  mounted() {
    if (this.existingMilestone) {
      this.label = this.existingMilestone.status;
      this.date = this.existingMilestone.date;
    } else {
      var d = new Date(this.job.date_start.date)?.getMonth();
      this.$refs.cal.currentMonth = d;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("setSelectedObject", null);
  },
  methods: {
    deleteMilestone() {
      this.deletePrompt(
        this.existingMilestone.status,
        "/v1/jobs/" +
          this.$route.params.uid +
          "/milestone/" +
          this.existingMilestone.uid
      );
    },
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        const formattedDate = new Date(this.date).toISOString().split("T")[0];
        var form = new FormData();
        form.append("label", this.label);
        form.append("date", formattedDate);

        var url =
          process.env.VUE_APP_ROOT_API +
          (this.existingMilestone
            ? "/v1/jobs/" +
              this.$route.params.uid +
              "/milestone/" +
              this.existingMilestone.uid
            : "/v1/jobs/" + this.$route.params.uid + "/milestone");
        this.$axios
          .post(url, form, {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            this.isLoading = false;
            fetchJob(this.$route.params.uid);
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
};
</script>